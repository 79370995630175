import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Block from './Block';

const ContainerHeader = styled(Block)`
  height: 64px;

  ${breakpoint('smallTablet')`
    height: 80px;
  `}

  ${breakpoint('tablet')`
    height: 91px;
  `}
`;

export default ContainerHeader;
