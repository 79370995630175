import PropTypes from 'prop-types';
import styled from 'styled-components';
import { grid } from 'styled-components-grid';
import { map } from 'styled-components-breakpoint';

import { margins, paddings, backgroundColor } from '../ui/utils';

const Grid = styled.div`
  ${props => grid(props)};
  ${({ position }) => (position ? `position: ${position};` : null)};

  width: 100%;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;

  ${({ flexDirection }) =>
    map(flexDirection, val => `flex-direction: ${val || 'row'};`)};
  ${({ minHeight }) =>
    map(minHeight, val => (val ? `min-height: ${val}px;` : ''))};

  ${({ height }) => (height ? `height: ${height};` : null)};
  ${props => backgroundColor(props.backgroundColor)};
  ${props => margins(props)};
  ${props => paddings(props)};
`;

Grid.propTypes = {
  halign: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  valign: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  wrap: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  flexDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

export default Grid;
