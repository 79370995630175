import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';

import { margins, paddings, backgroundColor } from '../ui/utils';

const Block = styled.div`
  ${({ display }) => map(display, val => `display: ${val};`)};
  ${({ position }) => (position ? `position: ${position}` : null)};

  ${({ alignItems }) => map(alignItems, val => `align-items: ${val};`)}
  ${({ justifyContent }) =>
    map(justifyContent, val => `justify-content: ${val};`)};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};

  ${props => backgroundColor(props.backgroundColor)};
  ${props => margins(props)};
  ${props => paddings(props)};
`;

export default Block;
