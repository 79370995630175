import React from 'react';
import { Link } from 'gatsby';
// import PropTypes from 'prop-types';

import Block from './Block';
import Container from './Container';
import P from './P';

const SiteFooter = () => (
  <Block backgroundColor="grayDark" px="baseUnit">
    <Container>
      <Block display="flex" justifyContent="space-between" py="baseUp4">
        <P mb="0">© {new Date().getFullYear()} Bokaplanen</P>
        <P mb="0">
          <Link to="/terms">Villkor</Link>
        </P>
      </Block>
    </Container>
  </Block>
);

SiteFooter.propTypes = {};

export default SiteFooter;
