import styled from 'styled-components';

import { backgroundColor, margins, paddings, color } from '../ui/utils';

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  display: block;
  border: 0;

  font-family: ${({ theme }) => theme.fontFamily.base};
  font-size: ${({ theme }) => theme.spacing.baseUp2};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding: ${({ theme }) => theme.spacing.baseUnit}
    ${({ theme }) => theme.spacing.baseUp8};
  border-radius: ${({ theme }) => theme.spacing.baseDown2};
  cursor: pointer;
  transition: box-shadow 0.2s;
  outline: none;

  ${props => color(props.color)};
  ${props => backgroundColor(props.backgroundColor)};
  ${props => margins(props)};
  ${props => paddings(props)};

  &:hover {
    box-shadow: 0 0 12px 0 ${({ theme }) => theme.colors.white};
  }

  &:active {
    box-shadow: inset 0 0 4px 0 ${({ theme }) => theme.colors.white};
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  a {
    color: ${({ theme }) => theme.colors.white};
  }
`;

Button.defaultProps = {
  color: 'white',
};

export default Button;
