import styled from 'styled-components';

import { margins, paddings, backgroundColor } from '../ui/utils';

const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;

  ${props => backgroundColor(props.backgroundColor)};
  ${props => margins(props)};
  ${props => paddings(props)};
`;

Container.propTypes = {};

export default Container;
