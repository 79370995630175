import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import H3 from './H3';
import HeaderLogoWrapper from './HeaderLogoWrapper';

const HeaderLogo = ({ color }) => (
  <StaticQuery
    query={graphql`
      query {
        white: file(relativePath: { eq: "logo-white.png" }) {
          childImageSharp {
            fluid(maxWidth: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        green: file(relativePath: { eq: "logo-green.png" }) {
          childImageSharp {
            fluid(maxWidth: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={logos => (
      <>
        <HeaderLogoWrapper>
          <Img fluid={logos[color].childImageSharp.fluid} alt="QuizRR home" />
        </HeaderLogoWrapper>
        <H3 color={color}>Bokaplanen</H3>
      </>
    )}
  />
);

HeaderLogo.defaultProps = {
  color: 'white',
};

HeaderLogo.propTypes = {
  color: PropTypes.string,
};

export default HeaderLogo;
