import { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';

export const siteColors = {
  white: '#ffffff',
  black: '#000000',
  grayLight: '#f4f4f4',
  grayDark: '#cccccc',
  grayDarker: '#666666',

  baseFont: '#000000',

  blue: '#1d1e6e',
  blueLight: '#57a5d2',

  green: '#3baf55',
  greenLight: '#d4f7d9',

  pink: '#ef7373',

  error: '#D63E3E',
  transparent: 'transparent',
};

export const backgroundColor = c => css`
  ${({ theme }) =>
    map(
      c,
      val =>
        `background-color: ${
          val ? theme.colors[val] : theme.colors.transparent
        };`,
    )};
`;

export const color = c => css`
  color: ${({ theme }) => (c ? theme.colors[c] : theme.colors.baseFont)};
`;
