export const spacing = {
  auto: 'auto',
  '0': '0',
  baseDown4: '0',
  baseDown3: '0.25rem',
  baseDown2: '0.5rem',
  baseDown1: '0.75rem',
  baseUnit: '1rem',
  baseUp1: '1.25rem',
  baseUp2: '1.5rem',
  baseUp3: '1.75rem',
  baseUp4: '2rem',
  baseUp5: '2.25rem',
  baseUp6: '2.5rem',
  baseUp7: '2.75rem',
  baseUp8: '3rem',
  baseUp9: '3.25rem',
  baseUp10: '3.5rem',
  baseUp11: '3.75rem',
  baseUp12: '4rem',
  baseUp13: '4.25rem',
  baseUp14: '4.5rem',
  baseUp15: '4.75rem',
  baseUp16: '5rem',
  baseUp17: '5.25rem',
  baseUp18: '5.5rem',
  baseUp19: '5.75rem',
  baseUp20: '6rem',
};
