import { breakpoints, spacing, siteColors } from './utils';

export default {
  breakpoints,
  spacing,
  fontFamily: {
    base: 'Rubik, sans-serif',
    heading: 'Rubik, sans-serif',
    button: 'Rubik, sans-serif',
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  colors: {
    ...siteColors,
  },
};
