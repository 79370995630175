import styled from 'styled-components';
import { grid } from 'styled-components-grid';

import { margins, paddings, backgroundColor } from '../ui/utils';

const Cell = styled.div`
  ${({ size, visible }) => grid.unit({ size, visible })};

  align-self: ${({ align }) => align || 'inherit'};
  justify-content: ${({ justifyContent }) => justifyContent || 'inherit'};
  ${({ position }) => `position: ${position};` || null};

  ${props => backgroundColor(props.backgroundColor)};
  ${props => margins(props)};
  ${props => paddings(props)};
`;

export default Cell;
