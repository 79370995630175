import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Block from './Block';

const NavBlock = styled(Block)`
  display: block;

  ${breakpoint('tablet')`
    display: flex;
  `};
`;

const MainLinkStyle = css`
  display: block;

  padding: ${({ theme }) => theme.spacing.baseUnit};
  text-align: center;

  text-decoration: none;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.spacing.baseUp2};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    text-decoration: none;
  }

  &.active {
    span {
      box-shadow: 0 2px 0 ${({ theme }) => theme.colors.white};
    }
  }

  ${breakpoint('tablet')`
    padding-top: ${({ theme }) => theme.spacing.baseDown2};
    padding-bottom: ${({ theme }) => theme.spacing.baseDown3};
    font-size: ${({ theme }) => theme.spacing.baseUnit};
    text-transform: uppercase;
  `};
`;

const NavLink = styled(Link)`
  ${MainLinkStyle}
`;

const ExternalLink = styled.a`
  ${MainLinkStyle}
`;

const pages = [
  // {
  //   href: '/',
  //   title: 'Start',
  //   type: 'internal',
  // },
];

const MainNavigation = () => (
  <NavBlock alignItems="center">
    {pages.map(({ title, href, type }) => (
      <Fragment key={`nav-link-${href}`}>
        {type === 'Web' ? (
          <ExternalLink href={href}>{title}</ExternalLink>
        ) : (
          <NavLink to={href} activeClassName="active">
            <span>{title}</span>
          </NavLink>
        )}
      </Fragment>
    ))}
  </NavBlock>
);

MainNavigation.propTypes = {};

export default MainNavigation;
