import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { breakpoints } from '../ui/utils';

const GlobalStyle = createGlobalStyle`
  ${reset};

  ${
    '' /* @import url('https://fonts.googleapis.com/css?family=Fjalla+One|Noto+Sans:400,700&display=swap'); */
  }
  @import url('https://fonts.googleapis.com/css?family=Rubik:400,400i,700&display=swap');

  *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    html {
      font-size: 16px;

      @media (min-width: ${breakpoints.tablet}px) {
        font-size: 18px;
      }
    }

    html,
    body,
    #___gatsby {
      height: 100%;
    }

    #___gatsby > div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    body {
      color: ${({ theme }) => theme.colors.black};
      background-color: ${({ theme }) => theme.colors.white};
      font-family: ${({ theme }) => theme.fontFamily.base};
      font-size: ${({ theme }) => theme.spacing.baseUnit};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      line-height: 1.3;
      -webkit-font-smoothing: antialiased;
      overscroll-behavior: none;
    }

    img,
    iframe {
      display: block;
      width: 100%;
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.blue};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${({ theme }) => theme.fontFamily.heading};
    }

    svg {
      display: block;
    }
`;

export default GlobalStyle;
