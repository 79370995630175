/* eslint-disable default-case */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';

import { hasDocument, hasWindow } from '../utils';

import Block from './Block';

const StickyHeaderContainer = styled(Block)`
  transition: all 0.2s;
  position: ${({ isSticky }) => (isSticky ? 'fixed' : 'absolute')};
  width: 100%;
  z-index: 2;
  background-color: ${({ isSticky, theme }) =>
    isSticky ? rgba(theme.colors.green, 0.9) : 'transparent'};
  top: 0;
`;

class StickyHeaderWrapper extends Component {
  state = {
    isSticky: false,
  };

  tick = false;

  lastScrollPosition = 0;

  componentDidMount() {
    if (hasDocument()) {
      document.addEventListener('scroll', this.onScroll, false);
    }
  }

  componentWillUnmount() {
    if (hasDocument()) {
      document.removeEventListener('scroll', this.onScroll, false);
    }
  }

  onScroll = () => {
    if (hasWindow()) {
      this.lastScrollPosition = window.pageYOffset || window.scrollY;
      this.requestTick();
    }
  };

  requestTick = () => {
    if (!this.tick) {
      window.requestAnimationFrame(this.update);
    }
    this.tick = true;
  };

  update = () => {
    this.tick = false;
    const { isSticky } = this.state;

    switch (true) {
      case this.lastScrollPosition > 0 && !isSticky:
        this.setState({ isSticky: true });
        break;
      case this.lastScrollPosition <= 0 && isSticky:
        this.setState({ isSticky: false });
        break;
      default:
        break;
    }
  };

  render() {
    const { children, hasPadding } = this.props;
    const { isSticky } = this.state;

    return (
      <StickyHeaderContainer
        as="header"
        isSticky={isSticky}
        py={{
          mobile: !isSticky ? 'baseDown2' : '0',
          tablet: !isSticky ? 'baseUnit' : '0',
        }}
        hasPadding={hasPadding}
      >
        {children}
      </StickyHeaderContainer>
    );
  }
}

StickyHeaderWrapper.defaultProps = {
  hasPadding: false,
};

StickyHeaderWrapper.propTypes = {
  children: PropTypes.shape({}).isRequired,
  hasPadding: PropTypes.bool,
};

export default StickyHeaderWrapper;
