import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const HeaderLogoWrapper = styled.div`
  height: 36px;
  width: 36px;
  margin-right: ${({ theme }) => theme.spacing.baseDown1};

  ${breakpoint('smallTablet')`
    height: 55px;
    width: 55px;
  `};
`;

export default HeaderLogoWrapper;
