import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { rgba } from 'polished';

import MainNavigation from './MainNavigation';
import Hamburger from './Hamburger';
import Block from './Block';
import HeaderLogo from './HeaderLogo';
import Container from './Container';
import ContainerHeader from './ContainerHeader';

const OffCanvas = styled(Block)`
  position: fixed;
  background-color: ${({ theme }) => rgba(theme.colors.green, 0.9)};
  transition: all 0.25s;
  transform: translate3d(0, ${({ isOpen }) => (isOpen ? '0' : '-100%')}, 0);

  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: -1;
  padding-top: 80px;

  ${breakpoint('tablet')`
    display: none;
  `};
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
`;

class SiteHeader extends Component {
  state = {
    offCanvasIsOpen: false,
  };

  onToggleMenu = () => {
    this.setState(state => ({
      offCanvasIsOpen: !state.offCanvasIsOpen,
    }));
  };

  render() {
    const { offCanvasIsOpen } = this.state;

    return (
      <>
        <Container>
          <ContainerHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px="baseUnit"
          >
            <LogoLink to="/">
              <HeaderLogo />
            </LogoLink>

            <Block display="flex" alignItems="center">
              <Block display={{ mobile: 'none', tablet: 'flex' }}>
                <MainNavigation />
              </Block>

              <Block display={{ mobile: 'none', tablet: 'none' }}>
                <Hamburger
                  onClick={this.onToggleMenu}
                  isActive={offCanvasIsOpen}
                />
              </Block>
            </Block>
          </ContainerHeader>
        </Container>

        <OffCanvas isOpen={offCanvasIsOpen}>
          <MainNavigation />
        </OffCanvas>
      </>
    );
  }
}

SiteHeader.propTypes = {};

export default SiteHeader;
