import React, { Component } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';

import { hasWindow } from '../utils';

import Block from './Block';
import Container from './Container';
import P from './P';
import Button from './Button';

const cookies = new Cookies();
const gaConsentCookieName = 'bokaplanenGaConsentCookie';

const AbsoluteBlock = styled(Block)`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => rgba(theme.colors.white, 0.95)};
`;

const ConsentButton = styled(Button)`
  font-size: ${({ theme }) => theme.spacing.baseDown1};
`;

const initializeGa = ({ justAccepted = false }) => {
  if (hasWindow()) {
    if (!window.ga) {
      ReactGA.initialize(process.env.GATSBY_GA_TRACKING_ID);

      if (justAccepted) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }
};

class CookieBanner extends Component {
  constructor() {
    super();

    const gaConsentCookie = cookies.get(gaConsentCookieName);

    this.state = {
      cookieExists: gaConsentCookie !== undefined,
    };

    this.acceptCookies = this.acceptCookies.bind(this);
    this.rejectCookies = this.rejectCookies.bind(this);

    if (gaConsentCookie === 'true') {
      initializeGa({ justAccepted: false });
    }
  }

  acceptCookies() {
    cookies.set(gaConsentCookieName, true, { path: '/' });

    initializeGa({ justAccepted: true });
    this.setState({
      cookieExists: true,
    });
  }

  rejectCookies() {
    cookies.set(gaConsentCookieName, false, { path: '/' });

    this.setState({
      cookieExists: true,
    });
  }

  render() {
    const { cookieExists } = this.state;

    return cookieExists ? null : (
      <AbsoluteBlock backgroundColor="grayDark" px="baseUnit">
        <Container>
          <Block
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="baseDown2"
          >
            <P mb="0">
              Vi använder Google Analytics för att lättare förstå våra besökares
              behov.
            </P>
            <Block display="flex">
              <ConsentButton
                backgroundColor="blueLight"
                py="baseDown2"
                px="baseUnit"
                mr="baseDown2"
                onClick={this.acceptCookies}
              >
                Acceptera
              </ConsentButton>
              <ConsentButton
                backgroundColor="grayDark"
                py="baseDown2"
                px="baseUnit"
                onClick={this.rejectCookies}
              >
                Neka
              </ConsentButton>
            </Block>
          </Block>
        </Container>
      </AbsoluteBlock>
    );
  }
}

CookieBanner.propTypes = {};

export default CookieBanner;
