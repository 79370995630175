import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const layerWidth = '28px';
const layerHeight = '3px';
const layerSpacing = '5px';

const HamburgerButton = styled.button`
  display: inline-block;
  cursor: pointer;
  border: 0;
  outline: 0;
  background-color: transparent;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
`;

const HamburgerBox = styled.span`
  width: ${layerWidth};
  height: calc(${layerHeight} * 3 + ${layerSpacing} * 2);
  display: inline-block;
  position: relative;
`;

const HamburgerInner = styled.span`
  display: block;
  top: 50%;
  margin-top: calc(${layerHeight} / -2);
  transform: ${({ isActive }) => (isActive ? 'rotate(45deg)' : 'rotate(0deg)')};
  transition-duration: 0.075s;
  transition-timing-function: ${({ isActive }) =>
    isActive
      ? 'cubic-bezier(0.215, 0.61, 0.355, 1);'
      : 'cubic-bezier(0.55, 0.055, 0.675, 0.19);'};
  transition-delay: ${({ isActive }) => (isActive ? '0.12s' : '0')};

  &,
  &::before,
  &::after {
    width: ${layerWidth};
    height: ${layerHeight};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 3px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: ${({ isActive }) =>
      isActive ? '0' : `calc((${layerSpacing} + ${layerHeight}) * -1)`};
    opacity: ${({ isActive }) => (isActive ? '0' : '1')};
    transition: ${({ isActive }) =>
      isActive
        ? 'top 0.075s ease, opacity 0.075s 0.12s ease;'
        : 'top 0.075s 0.12s ease, opacity 0.075s ease;'};
  }

  &::after {
    bottom: ${({ isActive }) =>
      isActive ? '0' : `calc((${layerSpacing} + ${layerHeight}) * -1)`};
    transform: ${({ isActive }) =>
      isActive ? 'rotate(-90deg)' : 'rotate(0deg)'};
    transition: ${({ isActive }) =>
      isActive
        ? 'bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)'
        : 'bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)'};
  }
`;

const Hamburger = ({ onClick, isActive }) => (
  <HamburgerButton onClick={onClick}>
    <HamburgerBox>
      <HamburgerInner isActive={isActive} />
    </HamburgerBox>
  </HamburgerButton>
);

Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Hamburger;
