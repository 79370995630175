import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import theme from '../ui/theme';

import GlobalStyle from './GlobalStyle';
import StickyHeaderWrapper from './StickyHeaderWrapper';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import CookieBanner from './CookieBanner';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />

      <StickyHeaderWrapper>
        <SiteHeader />
      </StickyHeaderWrapper>

      <main style={{ flex: 1 }}>{children}</main>

      <SiteFooter />

      <CookieBanner />
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
